import React from "react";

const projects = [
  {
    title: "Project 1",
    description: "A brief description of Project 1.",
    technologies: ["T1", "T2"],
  },
  {
    title: "Project 2",
    description: "A brief description of Project 2.",
    technologies: ["T1", "T2"],
  },
];

const Projects = () => {
  return (
    <section id="projects" className="bg-gray-800 py-16">
      <div className="container mx-auto px-6 text-white">
        <h2 className="text-3xl mb-6">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {projects.map((project, index) => (
            <div key={index} className="bg-gray-900 p-6 rounded-lg">
              <h3 className="text-2xl mb-4">{project.title}</h3>
              <p>{project.description}</p>
              <ul className="mt-4">
                {project.technologies.map((tech) => (
                  <li
                    key={tech}
                    className="inline-block bg-green-500 rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2"
                  >
                    {tech}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
