import React from "react";

const skills = [
  { name: "Test", level: 90 },
  { name: "Test 2", level: 80 },
];

const Skills = () => {
  return (
    <section id="skills" className="bg-gray-900 py-16">
      <div className="container mx-auto px-6 text-white">
        <h2 className="text-3xl mb-6">Skills</h2>
        <div>
          {skills.map((skill) => (
            <div key={skill.name} className="mb-4">
              <div className="flex justify-between">
                <span>{skill.name}</span>
                <span>{skill.level}%</span>
              </div>
              <div className="bg-gray-800 h-2 rounded-full">
                <div
                  className="bg-green-500 h-2 rounded-full"
                  style={{ width: `${skill.level}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
