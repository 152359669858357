import React from "react";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-4">
      <div className="container mx-auto px-6 text-white flex justify-between items-center">
        <div>
          &copy; {new Date().getFullYear()} Haris Musa. All rights reserved.
        </div>
        <Link
          to="hero"
          smooth={true}
          duration={500}
          className="cursor-pointer text-green-500 hover:text-green-600"
        >
          Back to Top
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
