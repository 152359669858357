import React from "react";

const About = () => {
  return (
    <section id="about" className="bg-gray-800 py-16">
      <div className="container mx-auto px-6 text-white">
        <h2 className="text-3xl mb-6">About Me</h2>
        <div className="flex">
          <p>
            Hello there! I'm Haris, a passionate Python engineer based in
            Karachi, Pakistan. With a foundation in Computer Science from Bahria
            University, my journey has led me through diverse realms of
            coding—from game modes in San Andreas Multiplayer Server to
            sophisticated machine learning projects using NLP and Computer
            Vision. Over the years, I've embraced Python and the enthralling
            realm of automation, always on the hunt to simplify and streamline
            tasks. Whether it's working with text through NLP or tackling tasks
            in computer vision, I thrive on solving complex problems. But it's
            not just about coding; it's about understanding and partnering with
            my clients to bring their ideas to life. Let's work together and
            make your vision a reality!
          </p>
        </div>
        <div className="mt-6 space-x-4">
          <a
            href="https://github.com/haris-musa"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github text-xl hover:text-green-500"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/haris-musa/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin text-xl hover:text-green-500"></i>
          </a>
          <a
            href="mailto:contact@harismusa.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-envelope text-xl hover:text-green-500"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
