import React from "react";
import { Typewriter } from "react-simple-typewriter";

const Hero = () => {
  return (
    <section
      id="hero"
      className="h-screen bg-black flex flex-col justify-center items-center text-white"
    >
      <h1 className="text-4xl mb-4">Haris Musa</h1>
      <div className="text-white">
        {/* ... */}
        <Typewriter
          words={["Python Engineer"]}
          loop={false}
          cursor
          cursorStyle="|"
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
          className="text-2xl"
        />
        {}
      </div>
    </section>
  );
};

export default Hero;
